import { useEffect, useRef } from 'react';

import useTranslation from 'next-translate/useTranslation';

import { Box, CircularProgress, Typography } from '@mui/material';
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import { getInterestMatchList, getLiveMatchList } from 'api/scoreboard';

import MatchCardWrapper from 'components/molecules/MatchCardWrapper';
import MatchCardSkeletons from 'components/organisms/PublicMatchList/PublicMatchListCard/MatchCardSkeletons';
import PublicMatchListCardWrapper from 'components/organisms/PublicMatchListCardWrapper';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useTanstackQuery from 'hooks/useTanstackQuery';

import { getMatchQueries } from 'utils/getQueries';

import ResponsiveBannerAdCard from '../GoogleAds/ResponsiveBannerAdCard';

const MainPageLive = ({
  country,
  timezone,
}: {
  country: string;
  timezone: string;
}) => {
  const { user, isProfileLoadProcessComplete } = useActiveAuth();
  const { infiniteQueryOptions } = useTanstackQuery();
  const { t } = useTranslation('main');
  const { isMobile } = useCheckDevice();

  const endPoint = useRef(null);

  const {
    data: liveMatchList,
    fetchNextPage: liveMatchFetchNextPage,
    hasNextPage: liveMatchHasNextPage,
    isLoading: liveMatchIsInitialLoading,
    isFetching: liveMatchIsFetching,
    isFetchingNextPage: liveMatchIsFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['matchList', 'liveMatch'],
    queryFn: ({ pageParam = 0 }) =>
      getLiveMatchList(
        getMatchQueries({
          country,
          disclosure: 'public',
          language:
            user && user.language ? user.language : window.navigator.language,
          timezone,
          offset: pageParam,
        }),
      ),
    ...infiniteQueryOptions,
    enabled: isProfileLoadProcessComplete,
  });

  const {
    data: recentlyEndedMatchList,
    isFetching: isRecentlyEndedMatchListFetching,
  } = useQuery({
    queryKey: ['matchList', 'recentlyEndedMatch'],
    queryFn: () =>
      getInterestMatchList(
        getMatchQueries({
          disclosure: 'public',
          status: 'end',
          country,
          language:
            user && user.language ? user.language : window.navigator.language,
          timezone,
          offset: 0,
          limit: 10,
        }),
      ),
    enabled: isProfileLoadProcessComplete,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    let endPointCurrent = endPoint.current;

    const callBack = async (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        if (liveMatchHasNextPage && !liveMatchIsFetchingNextPage) {
          await liveMatchFetchNextPage();
        }
      }
    };

    const observer = new IntersectionObserver(callBack, {
      rootMargin: '100px',
    });

    if (endPoint.current) {
      endPointCurrent = endPoint.current;
      observer.observe(endPoint.current);
    }

    return () => {
      if (endPointCurrent) observer.unobserve(endPointCurrent);
    };
  }, [
    liveMatchFetchNextPage,
    liveMatchHasNextPage,
    liveMatchIsFetchingNextPage,
  ]);

  return (
    <>
      <Box
        sx={{
          mt: { xs: '20px', sm: '28px', lg: 4 },
          mx: { xs: 0, sm: '40px', lg: 0 },
        }}
      >
        <Typography
          variant="h3Bold"
          sx={{
            mx: { xs: 2, sm: 0 },
            mb: { xs: '10px', sm: '12px', lg: '22px' },
            fontSize: { xs: '20px', sm: '24px' },
          }}
        >
          {t('subTitle.live')}
        </Typography>

        <PublicMatchListCardWrapper
          tabType="live"
          resultPages={liveMatchList?.pages}
          isLoading={
            liveMatchIsInitialLoading ||
            (liveMatchIsFetching && !liveMatchIsFetchingNextPage)
          }
        />
      </Box>

      <ResponsiveBannerAdCard adId="메인_첫번째컨텐츠리스트하단_배너_공통" />

      {liveMatchIsFetchingNextPage ? (
        <Box sx={{ mx: { xs: 0, sm: '40px', lg: 0 } }}>
          {!isMobile ? (
            <MatchCardSkeletons
              mode="default"
              sx={{ px: { xs: 0, sm: 2, lg: 0 } }}
            />
          ) : null}
          <Box textAlign="center">
            <CircularProgress size={30} sx={{ color: 'gray.500' }} />
          </Box>
        </Box>
      ) : (
        <Box ref={endPoint} />
      )}

      {liveMatchList && liveMatchList.pages[0].matches.length <= 12 ? (
        <MatchCardWrapper
          title={t('subTitle.recentlyEndedMatch')}
          matchListData={recentlyEndedMatchList}
          isFetching={isRecentlyEndedMatchListFetching}
          wrapperSx={{
            mt: { xs: 3, sm: 5, lg: 9 },
            '& .match-card-wrapper-title': {
              textAlign: 'center',
            },
          }}
        />
      ) : null}
    </>
  );
};

export default MainPageLive;
