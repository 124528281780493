import { useState } from 'react';

const useVideoPlayList = () => {
  const [canPlayVideoList, setCanPlayVideoList] = useState<number[]>([]);
  const [focusVideoIndex, setFocusVideoIndex] = useState<number>(-1);

  const addPlayList = (newVideoIndex: number) => {
    if (!canPlayVideoList.includes(newVideoIndex)) {
      setCanPlayVideoList([...canPlayVideoList, newVideoIndex]);
      setFocusVideoIndex(Math.min(...canPlayVideoList, newVideoIndex));
    }
  };

  const deletePlayList = (targetVideoIndex: number) => {
    if (canPlayVideoList.includes(targetVideoIndex)) {
      const newVideoList = canPlayVideoList.filter(
        (videoIndex: number) => videoIndex !== targetVideoIndex,
      );
      setCanPlayVideoList(newVideoList);
      if (newVideoList.length > 0)
        setFocusVideoIndex(Math.min(...newVideoList));
      else setFocusVideoIndex(-1);
    }
  };

  return { focusVideoIndex, addPlayList, deletePlayList };
};

export default useVideoPlayList;
