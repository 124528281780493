import { useEffect, useRef, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import DeleteIcon from '@mui/icons-material/CancelOutlined';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import { getEventList } from 'api/event';
import useChannelApi from 'api/hooks/useChannelApi';
import useScoreboardApi from 'api/hooks/useScoreboardApi';
import { getStaffPickDataList } from 'api/scoreboard';

import MatchCardWrapper from 'components/molecules/MatchCardWrapper';
import MultiSportPicker from 'components/molecules/MultiSportPicker';
import NoResultGuideBox from 'components/molecules/NoResultGuideBox';
import MatchCardSkeletons from 'components/organisms/PublicMatchList/PublicMatchListCard/MatchCardSkeletons';
import PublicMatchListCardWrapper from 'components/organisms/PublicMatchListCardWrapper';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useTanstackQuery from 'hooks/useTanstackQuery';

import type { SportType } from 'types/scoreboardData';

import { getEventQueries, getMatchQueries } from 'utils/getQueries';

import EventCardWrapper from '../EventCardWrapper';
import ResponsiveBannerAdCard from '../GoogleAds/ResponsiveBannerAdCard';

const MainPageForYou = ({
  country,
  timezone,
}: {
  country: string;
  timezone: string;
}) => {
  const { getForYouMatchList } = useScoreboardApi();
  const { updateChannelInfo } = useChannelApi();
  const { isProfileLoadedUser, user, channel, updateChannel } = useActiveAuth();
  const { infiniteQueryOptions, invalidateQueries } = useTanstackQuery();
  const { t } = useTranslation('main');
  const { isMobile } = useCheckDevice();

  const [isMultiSportPickerOpen, setIsMultiSportPickerOpen] = useState(false);

  const endPoint = useRef(null);

  const hasInterest = channel.interests && channel.interests.length > 0;

  const {
    data: forYouEventListData,
    isFetching: isForYouEventListDataFetching,
  } = useQuery({
    queryKey: ['eventList', 'forYouEvent', channel.interests],
    queryFn: async () =>
      await getEventList(
        getEventQueries({
          orderByCountry: country,
          orderByLanguage:
            user && user.language ? user.language : window.navigator.language,

          eventTypesFilter: ['sport'],
          sportTypesFilter: channel.interests || undefined,

          useOrderByDeadlineImminent: true,
        }),
      ),
    enabled: isProfileLoadedUser && channel.interests?.length !== 0,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: forYouMatchList,
    fetchNextPage: forYouMatchFetchNextPage,
    hasNextPage: forYouMatchHasNextPage,
    isLoading: forYouMatchIsInitialLoading,
    isFetching: forYouMatchIsFetching,
    isFetchingNextPage: forYouMatchIsFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['matchList', 'forYouMatch', channel.interests],
    queryFn: async ({ pageParam = 0 }) =>
      await getForYouMatchList(
        getMatchQueries({
          country,
          disclosure: 'public',
          language:
            user && user.language ? user.language : window.navigator.language,
          timezone,
          offset: pageParam,
        }),
      ),
    enabled: isProfileLoadedUser,
    ...infiniteQueryOptions,
  });

  const { data: staffPickMatchList, isFetching: isStaffPickMatchListFetching } =
    useQuery({
      queryKey: ['matchList', 'staffPick'],
      queryFn: async () =>
        await getStaffPickDataList(
          getMatchQueries({
            country,
            disclosure: 'public',
            language:
              user && user.language ? user.language : window.navigator.language,
            timezone,
            offset: 0,
            limit: 10,
          }),
        ),
      enabled: isProfileLoadedUser,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    });

  
  const handleChangeSportsList = async (newInterestList: SportType[]) => {
    await updateChannelInfo(channel.uid, {
      interests: newInterestList,
    });
    await invalidateQueries(['eventList', 'matchList']);
    updateChannel('interests', newInterestList);    
    
    if (!channel.interests) return;
  };

  useEffect(() => {
    let endPointCurrent = endPoint.current;

    const callBack = async (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        if (forYouMatchHasNextPage && !forYouMatchIsFetchingNextPage) {
          await forYouMatchFetchNextPage();
        }
      }
    };

    const observer = new IntersectionObserver(callBack, {
      rootMargin: '100px',
    });

    if (endPoint.current) {
      endPointCurrent = endPoint.current;
      observer.observe(endPoint.current);
    }

    return () => {
      if (endPointCurrent) observer.unobserve(endPointCurrent);
    };
  }, [
    forYouMatchFetchNextPage,
    forYouMatchHasNextPage,
    forYouMatchIsFetchingNextPage,
  ]);

  return (
    <Box sx={{ pb: 10 }}>
      {hasInterest ? (
        <>
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex' },
              alignItems: 'center',
              mt: { xs: '12px', sm: 2, lg: 3 },
              ml: { xs: 2, sm: 5, lg: 0 },
            }}
          >
            <Typography
              variant="h5Bold"
              sx={{ mr: { xs: '12px', lg: '20px' }, mb: { xs: 1, sm: 0 } }}
            >
              {t('subTitle.preferredSports')}
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
              {channel.interests &&
                channel.interests.map((interest) => (
                  <Chip
                    key={interest}
                    label={
                      <Typography variant="chip">
                        {t(`sportType.${interest}`)}
                      </Typography>
                    }
                    deleteIcon={<DeleteIcon />}
                    sx={({ palette: { mode } }) => ({
                      height: { xs: '30px', sm: '36px' },
                      bgcolor:
                        mode === 'light'
                          ? 'rgba(55, 197, 86, 0.1)'
                          : 'transparent',
                      border: '1px solid',
                      borderColor: 'primaryColor.500',
                      borderRadius: '30px',
                      color: 'primaryColor.500',

                      '& svg.MuiChip-deleteIcon': {
                        color: 'primaryColor.500',
                        '&:hover': {
                          color: 'primaryColor.500',
                        },
                      },
                    })}
                    onDelete={() => {
                      handleChangeSportsList(
                        (channel.interests || []).filter(
                          (item) => item !== interest,
                        ),
                      );
                    }}
                  />
                ))}

              <Chip
                label={
                  <Typography variant="chip">
                    {t('forYou.selcetSports')}
                  </Typography>
                }
                sx={({ palette: { mode } }) => ({
                  height: { xs: '30px', sm: '36px' },
                  px: 1,
                  bgcolor: mode === 'dark' ? 'transparent' : 'gray.50',
                  border: '1px solid',
                  borderColor: 'gray.600',
                  borderRadius: '30px',
                  color: mode === 'dark' ? 'gray.600' : 'gray.500',
                  cursor: 'pointer',

                  '&:hover': {
                    bgcolor: mode === 'dark' ? 'transparent' : 'gray.50',
                  },
                })}
                onClick={() => {
                  setIsMultiSportPickerOpen(true);
                }}
              />
            </Box>
          </Box>

          <EventCardWrapper
            eventListData={forYouEventListData}
            isFetching={isForYouEventListDataFetching}
          />

          <ResponsiveBannerAdCard adId="메인_첫번째컨텐츠리스트하단_배너_공통" />

          <Box
            sx={{
              mt: { xs: 3, sm: 5, lg: 9 },
              mx: { xs: 0, sm: '40px', lg: 0 },
            }}
          >
            <Typography
              variant="h3Bold"
              sx={{
                mb: { xs: '10px', sm: '12px', lg: '22px' },
                mx: { xs: 2, sm: 0 },
                fontSize: { xs: '20px', sm: '24px' },
              }}
            >
              {t('subTitle.match')}
            </Typography>

            <PublicMatchListCardWrapper
              tabType="staffPick"
              resultPages={forYouMatchList?.pages}
              isLoading={
                forYouMatchIsInitialLoading ||
                (forYouMatchIsFetching && !forYouMatchIsFetchingNextPage)
              }
            />
          </Box>

          {forYouMatchIsFetchingNextPage ? (
            <Box sx={{ mx: { xs: 0, sm: '40px', lg: 0 } }}>
              {!isMobile ? (
                <MatchCardSkeletons
                  mode="default"
                  sx={{ px: { xs: 0, sm: 2, lg: 0 } }}
                />
              ) : null}
              <Box textAlign="center">
                <CircularProgress size={30} sx={{ color: 'gray.500' }} />
              </Box>
            </Box>
          ) : (
            <Box ref={endPoint} />
          )}
        </>
      ) : (
        <Box sx={{ mt: { xs: '20px', sm: '28px', lg: 4 } }}>
          <Typography
            variant="h3Bold"
            sx={{
              mb: { xs: '10px', sm: '12px', lg: '22px' },
              ml: { xs: 2, sm: 5, lg: 0 },
              fontSize: { xs: '20px', sm: '24px' },
            }}
          >
            {t('subTitle.match')}
          </Typography>

          <NoResultGuideBox
            type="forYou"
            onClick={() => {
              setIsMultiSportPickerOpen(true);
            }}
          />
        </Box>
      )}

      <MultiSportPicker
        open={isMultiSportPickerOpen}
        initSportsList={channel.interests || []}
        onOpen={() => {
          setIsMultiSportPickerOpen(true);
        }}
        onClose={() => {
          setIsMultiSportPickerOpen(false);
        }}
        onChangeSportsList={async (selectedSportsList: SportType[]) => {
          await handleChangeSportsList(selectedSportsList);
        }}
      />

      {!hasInterest ? (
        <MatchCardWrapper
          title={t('subTitle.howAboutThis')}
          matchListData={staffPickMatchList}
          isFetching={isStaffPickMatchListFetching}
          wrapperSx={{
            mt: { xs: 3, sm: 5, lg: 9 },
            '& .match-card-wrapper-title': {
              textAlign: 'center',
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default MainPageForYou;
