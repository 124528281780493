import { useEffect, useRef, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import BackIcon from '@mui/icons-material/ArrowBackIosRounded';
import NextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import MoreIcon from '@mui/icons-material/MoreHorizRounded';
import { Box, IconButton, Typography, useTheme } from '@mui/material';

import LabeledSportsIcon from 'components/molecules/LabeledSportsIcon';

import useCheckDevice from 'hooks/useCheckDevice';

import type { SportType } from 'types/scoreboardData';

import BottomSheetSportsTypePicker from './BottomSheetSportsTypePicker';

const SportsIconTabs = ({
  currentSportType,
  sportPlusTabTypeList,
  handleChangeSportsType,
}: {
  currentSportType: SportType;
  sportPlusTabTypeList: SportType[];
  handleChangeSportsType: (selectedSportType: SportType) => void;
}) => {
  const {
    palette: { mode },
  } = useTheme();
  const { isMobile } = useCheckDevice();
  const { t } = useTranslation('main');

  const [scrollPosition, setScrollPosition] = useState('left');
  const [isSportsPickerOpen, setIsSportsPickerOpen] = useState(false);

  const tabButtonWrapperEl = useRef<HTMLDivElement>(null);

  const handleClickArrowButton = (direction: 'left' | 'right') => {
    if (tabButtonWrapperEl.current !== null) {
      const calculatedScrollLeft =
        direction === 'left'
          ? tabButtonWrapperEl.current.scrollLeft -
            tabButtonWrapperEl.current.clientWidth
          : tabButtonWrapperEl.current.scrollLeft +
            tabButtonWrapperEl.current.clientWidth;
      tabButtonWrapperEl.current.scrollTo({
        left: calculatedScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleClickSportTypeChip = (sportType: SportType) => {
    handleChangeSportsType(sportType);

    if (tabButtonWrapperEl.current !== null) {
      const currentFocusIndex = sportPlusTabTypeList.findIndex(
        (sportTypeItem) => sportTypeItem === sportType,
      );

      const widthPerComponent =
        tabButtonWrapperEl.current.scrollWidth /
        (sportPlusTabTypeList.length - 1);

      const calculatedScrollLeft =
        currentFocusIndex * widthPerComponent -
        tabButtonWrapperEl.current.clientWidth / 2;

      tabButtonWrapperEl.current.scrollTo({
        left: calculatedScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const container = tabButtonWrapperEl.current;
    const handleScroll = () => {
      if (container) {
        const scrollLeft = container.scrollLeft;
        const scrollWidth = container.scrollWidth;
        const containerWidth = container.clientWidth;
        const atRightEnd = scrollWidth - (scrollLeft + containerWidth) < 20;

        if (atRightEnd) setScrollPosition('right');
        else if (scrollLeft < 20) setScrollPosition('left');
        else setScrollPosition('middle');
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mr: 2,
            }}
          >
            <Typography variant="body2Bold" sx={{ mt: '12px', mb: '10px' }}>
              {`${t('mainMenu.sports')}+`}
            </Typography>
            <IconButton
              onClick={() => setIsSportsPickerOpen(true)}
              sx={{ width: '32px', height: '32px' }}
            >
              <MoreIcon />
            </IconButton>
          </Box>

          <BottomSheetSportsTypePicker
            open={isSportsPickerOpen}
            onClose={() => setIsSportsPickerOpen(false)}
            onOpen={() => setIsSportsPickerOpen(true)}
            sportsList={sportPlusTabTypeList}
            selectedSportType={currentSportType}
            onClickSportsIcon={(sportType: SportType) => {
              handleClickSportTypeChip(sportType);
              setIsSportsPickerOpen(false);
            }}
          />
        </>
      ) : null}

      <Box
        ref={tabButtonWrapperEl}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          flexWrap: 'nowrap',
          rowGap: 2,
          mb: { xs: 0, sm: '24px' },
          mt: { xs: 0, sm: '32px' },
          height: { xs: '106px', sm: '123px' },

          '::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {sportPlusTabTypeList.map((sportType) => (
          <LabeledSportsIcon
            key={sportType}
            isActive={currentSportType === sportType}
            sportType={sportType}
            onClick={() => {
              handleClickSportTypeChip(sportType);
            }}
          />
        ))}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: { xs: '80%', sm: '100%' },
        }}
      >
        <Box
          sx={{
            pointerEvents: 'none',
            display: scrollPosition === 'left' ? 'none' : 'block',
            position: 'absolute',
            left: 0,
            width: '10%',
            maxWidth: '120px',
            height: '100%',
            background:
              mode === 'dark'
                ? 'linear-gradient(90deg,#121212 0%, rgba(18, 18, 18, 0.00) 100%)'
                : 'linear-gradient(90deg,#fff 0%, rgba(18, 18, 18, 0.00) 100%)',
            zIndex: 12,
          }}
        />
        <Box
          sx={{
            pointerEvents: 'none',
            display: scrollPosition === 'right' ? 'none' : 'block',
            position: 'absolute',
            right: 0,
            width: '10%',
            maxWidth: '120px',
            height: '100%',
            background:
              mode === 'dark'
                ? 'linear-gradient(90deg, rgba(18, 18, 18, 0.00) 0%, #121212 100%)'
                : 'linear-gradient(90deg, rgba(18, 18, 18, 0.00) 0%, #fff 100%)',
            zIndex: 12,
          }}
        />
        <IconButton
          onClick={() => {
            handleClickArrowButton('left');
          }}
          sx={{
            display: { xs: 'none', lg: 'flex' },
            opacity: scrollPosition === 'left' ? 0 : 1,
            position: 'absolute',
            left: '12px',
            width: '38px',
            height: '38px',
            top: '20px',
            border: '1px solid',
            borderColor: mode === 'dark' ? 'gray.600' : '#D9D9D9',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            bgcolor: mode === 'dark' ? 'gray.100' : 'white',
            zIndex: 12,

            ':hover': { bgcolor: mode === 'dark' ? 'gray.100' : 'white' },
          }}
        >
          <BackIcon sx={{ color: 'gray.500' }} />
        </IconButton>
        <IconButton
          onClick={() => {
            handleClickArrowButton('right');
          }}
          sx={{
            display: { xs: 'none', lg: 'flex' },
            opacity: scrollPosition === 'right' ? 0 : 1,
            position: 'absolute',
            right: '12px',
            width: '38px',
            height: '38px',
            top: '20px',
            border: '1px solid',
            borderColor: mode === 'dark' ? 'gray.600' : '#D9D9D9',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            bgcolor: mode === 'dark' ? 'gray.100' : 'white',
            zIndex: 12,

            ':hover': { bgcolor: mode === 'dark' ? 'gray.100' : 'white' },
          }}
        >
          <NextIcon sx={{ color: 'gray.500' }} />
        </IconButton>
      </Box>
    </>
  );
};
export default SportsIconTabs;
