import type { SportType } from 'types/scoreboardData';

const sportTypeList: SportType[] = [
  'all',
  'universal',
  'baseball',
  'basketball',
  'pickleball',
  'soccer',
  'taekwondo',
  'futsal',
  'tennis',
  'volleyball',
  'badminton',
  'hockey',
  'iceHockey',
  'esports',
  'billiard',
  'cricket',
  'handball',
  'golf',
  'squash',
  'football',
  'athletics',
  'bodybuilding',
  'tableTennis',
  'jokgu',
  'sepakTakraw',
  'climbing',
  'rugby',
];

export default sportTypeList;
