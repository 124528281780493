import { useRef, useState } from 'react';

import dynamic from 'next/dynamic';

import type { SxProps} from '@mui/material';
import { Box, Typography } from '@mui/material';

import CustomCircularProgress from 'components/atoms/CustomCircularProgress';
import MatchCardSkeleton from 'components/atoms/MatchCardSkeleton';
import BoxWithArrowIcon from 'components/molecules/BoxWithArrowIcon';
import NoResultGuideBox from 'components/molecules/NoResultGuideBox';
import PublicMatchListCard from 'components/organisms/PublicMatchList/PublicMatchListCard';

import useActiveAuth from 'hooks/useActiveAuth';

import type { ResultPagesType } from 'types/matchData';

const MatchGeneratePopup = dynamic(
  () => import('components/organisms/MatchGeneratePopup'),
  { loading: () => <CustomCircularProgress /> },
);

const MatchCardWrapper = ({
  idForGTM,
  title,
  matchListData,
  isFetching,
  wrapperSx,
}: {
  idForGTM?: string;
  title: string;
  matchListData: ResultPagesType | undefined;
  isFetching?: boolean;
  wrapperSx?: SxProps;
}) => {
  const { user, isAuthenticatedUser, signIn } = useActiveAuth();

  const [isMatchPopupOpen, setIsMatchPopupOpen] = useState(false);

  const cardWrapperEl = useRef<HTMLDivElement>(null);

  const isEmpty = matchListData && matchListData.matches.length <= 0;

  const handleClickMatchCreateBtn = () => {
    if (!isAuthenticatedUser) {
      signIn();
      return;
    }

    setIsMatchPopupOpen(true);
  };

  return (
    <Box
      id={idForGTM}
      sx={{
        mt: 4,
        ...wrapperSx,
      }}
    >
      <Typography
        className="match-card-wrapper-title"
        variant="h3Bold"
        sx={{
          mb: { xs: '10px', sm: '12px', lg: '22px' },
          ml: { xs: 2, sm: 5, lg: 0 },
          fontSize: { xs: '20px', sm: '24px' },
          color: isFetching ? 'transparent' : null,
        }}
      >
        {title}
      </Typography>

      <BoxWithArrowIcon
        className="gtm-match-browse-btn"
        childrenWrapperRef={cardWrapperEl}
        scrollGap={400}
        top="calc(30%)"
      >
        <Box
          className="gtm-match-list-area"
          ref={cardWrapperEl}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            columnGap: { xs: 1, sm: '12px', lg: 2 },
            scrollSnapType: { lg: 'x mandatory' },
            '::-webkit-scrollbar': { display: 'none' },
            px: { xs: 2, sm: 5, lg: 0 },
          }}
        >
          {matchListData === undefined || isFetching ? (
            <Box
              sx={{
                display: 'flex',
                columnGap: { xs: 1, sm: '12px', lg: 2 },
                overflowX: 'hidden',
              }}
            >
              <MatchCardSkeleton mode="default" />
              <MatchCardSkeleton mode="default" />
              <MatchCardSkeleton mode="default" />
              <MatchCardSkeleton mode="default" />
            </Box>
          ) : (
            matchListData.matches.map((matchData, idx) => (
              <PublicMatchListCard
                key={matchData.id}
                pageId="default"
                idx={idx}
                isMyPage={matchData.uid === user?.id}
                isFixedSize
                matchListData={matchData}
              />
            ))
          )}
        </Box>
      </BoxWithArrowIcon>

      {!isFetching && isEmpty ? (
        <NoResultGuideBox type="match" onClick={handleClickMatchCreateBtn} />
      ) : null}

      {isMatchPopupOpen ? (
        <MatchGeneratePopup
          open={isMatchPopupOpen}
          close={() => setIsMatchPopupOpen(false)}
        />
      ) : null}
    </Box>
  );
};

export default MatchCardWrapper;
