import type { ReactNode } from 'react';

import type {
  ButtonProps} from '@mui/material';
import {
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const CustomCancelButton = ({
  title,
  isLoading,
  sx,
  ...buttonProps
}: { title: ReactNode; isLoading?: boolean } & ButtonProps) => {
  const {
    breakpoints,
    palette: { mode, deprecatedGray },
  } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Button
      variant="contained"
      sx={{
        border: mode === 'dark' ? 'auto' : 0,
        color: mode === 'dark' ? 'white' : deprecatedGray[40],
        backgroundColor:
          mode === 'dark' ? deprecatedGray[15] : deprecatedGray[90],
        '&:hover': {
          backgroundColor:
            mode === 'dark' ? deprecatedGray[20] : deprecatedGray[70],
          borderColor: mode === 'dark' ? 'white' : deprecatedGray[40],
          border: mode === 'dark' ? 'auto' : 0,
        },
        ':disabled': {
          border: mode === 'dark' ? 'auto' : 'none',
          backgroundColor: 'transparent',
        },
        ...sx,
      }}
      {...buttonProps}
    >
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <Typography variant={isMobile ? 'body2' : 'body1'}>{title}</Typography>
      )}
    </Button>
  );
};

export default CustomCancelButton;
