import type { ReactNode, RefObject} from 'react';
import { useState } from 'react';

import PrevArrowIcon from '@mui/icons-material/ArrowBackIosRounded';
import NextArrowIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, IconButton, useTheme } from '@mui/material';

const BoxWithArrowIcon = ({
  className,
  children,
  childrenWrapperRef,
  scrollGap,
  top,
  isHide = false,
}: {
  className?: string;
  children: ReactNode;
  childrenWrapperRef: RefObject<HTMLDivElement>;
  scrollGap: number;
  top?: string;
  isHide?: boolean;
}) => {
  const {
    palette: { mode },
  } = useTheme();

  const [scrollLeft, setScrollLeft] = useState(0);
  const hideLeftArrow = scrollLeft <= scrollGap / 2;
  const hideRightArrow =
    (childrenWrapperRef.current &&
      childrenWrapperRef.current.scrollWidth -
        childrenWrapperRef.current.clientWidth <
        scrollLeft) ||
    (childrenWrapperRef.current &&
      childrenWrapperRef.current.scrollWidth ===
        childrenWrapperRef.current.clientWidth);

  const commonButtonSx = {
    display: { xs: 'none', lg: isHide ? 'none' : 'flex' },
    position: 'absolute',
    top: top ? top : 'calc(50% - 15px)',
    width: '38px',
    height: '38px',
    border: '1px solid',
    borderColor: mode === 'dark' ? 'gray.600' : '#D9D9D9',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    bgcolor: mode === 'dark' ? 'gray.100' : 'white',

    transition: 'opacity .3s',

    '&:hover': {
      bgcolor: mode === 'dark' ? 'gray.100' : 'white',
    },
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton
        className={className}
        disableRipple
        sx={{
          ...commonButtonSx,
          left: '12px',
          opacity: hideLeftArrow ? 0 : 1,
          zIndex: hideLeftArrow ? -1 : 101,
        }}
        onClick={() => {
          if (childrenWrapperRef.current !== null) {
            const calculatedScrollLeft =
              childrenWrapperRef.current.scrollLeft - scrollGap;
            setScrollLeft(calculatedScrollLeft);

            childrenWrapperRef.current.scrollTo({
              left: calculatedScrollLeft,
              behavior: 'smooth',
            });
          }
        }}
      >
        <PrevArrowIcon sx={{ color: 'gray.500' }} />
      </IconButton>

      {children}

      <IconButton
        className={className}
        disableRipple
        sx={{
          ...commonButtonSx,
          right: '12px',
          opacity: hideRightArrow ? 0 : 1,
          zIndex: hideRightArrow ? -1 : 40,
        }}
        onClick={() => {
          if (childrenWrapperRef.current !== null) {
            const calculatedScrollLeft =
              childrenWrapperRef.current.scrollLeft + scrollGap;
            setScrollLeft(calculatedScrollLeft);

            childrenWrapperRef.current.scrollTo({
              left: calculatedScrollLeft,
              behavior: 'smooth',
            });
          }
        }}
      >
        <NextArrowIcon sx={{ color: 'gray.500' }} />
      </IconButton>
    </Box>
  );
};

export default BoxWithArrowIcon;
