import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRouter } from 'next/router';

import PrevImageIcon from '@mui/icons-material/ArrowBackIosRounded';
import NextImageIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AutoSlidePauseIcon from '@mui/icons-material/PauseRounded';
import AutoSlidePlayIcon from '@mui/icons-material/PlayArrowRounded';
import { Box, IconButton } from '@mui/material';

import useCheckDevice from 'hooks/useCheckDevice';

import debouncing from 'utils/debouncing';

const AUTO_SLIDE_INTERVAL = 5;
const BANNER_IMAGE_HEIGHT = 471;
const BANNER_IMAGE_WIDTH = 824;

const MainPageBanner = ({
  mainPageBannerInfo,
}: {
  mainPageBannerInfo: {
    imageLink: string;
    clickLink: string;
    isLocalLink: boolean;
    postEndDate?: string;
  }[];
}) => {
  const { locale, push } = useRouter();
  const { isMobile } = useCheckDevice();

  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [isAutoSlideMode, setIsAutoSlideMode] = useState(true);
  const [isImgChange, setIsImgChange] = useState(true);

  const setTimeoutId = useRef<number | undefined>(undefined);

  const BANNER_IMAGE_COUNT = useMemo(
    () => mainPageBannerInfo.length,
    [mainPageBannerInfo],
  );

  const clearTimeout = () => {
    window.clearTimeout(setTimeoutId.current);
    setIsImgChange(false);
  };

  const handleClickPrevBtn = () => {
    setCurrentImageIndex(() => {
      if (currentImageIndex === 1) return BANNER_IMAGE_COUNT;
      return currentImageIndex - 1;
    });

    clearTimeout();
  };

  const handleClickNextBtn = useCallback(() => {
    setCurrentImageIndex(() => {
      if (currentImageIndex === BANNER_IMAGE_COUNT) return 1;
      return currentImageIndex + 1;
    });

    clearTimeout();
  }, [currentImageIndex, BANNER_IMAGE_COUNT]);

  useEffect(() => {
    const preloadImage = () => {
      const preloadImageList: { src: string }[] = [];
      for (let i = 0; i < BANNER_IMAGE_COUNT; i++) {
        preloadImageList[i] = new Image();
        preloadImageList[i].src = mainPageBannerInfo[i].imageLink;
      }
    };

    if (window.innerWidth > 600) preloadImage();
  }, [mainPageBannerInfo, locale, BANNER_IMAGE_COUNT]);

  useEffect(() => {
    const handleAutoSlideMode = () => {
      if (!isImgChange) {
        setTimeoutId.current = window.setTimeout(() => {
          setIsImgChange(true);
        }, AUTO_SLIDE_INTERVAL * 1000);
      } else {
        handleClickNextBtn();
      }
    };

    if (isAutoSlideMode && !isMobile) handleAutoSlideMode();
    else clearTimeout();
  }, [isAutoSlideMode, isImgChange, isMobile, handleClickNextBtn]);

  useEffect(() => {
    setCurrentImageIndex(1);
    clearTimeout();
  }, [locale]);

  return (
    <Box
      id="gtm-main-banner-area"
      onClick={() => {
        const { isLocalLink, clickLink } =
          mainPageBannerInfo[
            locale === 'en' && currentImageIndex > 4 ? 1 : currentImageIndex - 1
          ];

        if (isLocalLink) push(clickLink);
        else window.open(clickLink);
      }}
      sx={{
        display: { xs: 'none', sm: 'block' },
        position: 'relative',
        cursor: 'pointer',
        width: { sm: '520px', md: '820px', lg: '824px' },
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          maxWidth: `${BANNER_IMAGE_WIDTH}px`,
          aspectRatio: `${BANNER_IMAGE_WIDTH} / ${BANNER_IMAGE_HEIGHT}`,
          backgroundImage: `url(${
            mainPageBannerInfo[
              locale === 'en' && currentImageIndex > 4
                ? 1
                : currentImageIndex - 1
            ].imageLink
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          mx: 'auto',
          transition: 'background-image .5s ',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          justifyContent: 'space-between',
          width: '95%',
          zIndex: 2,
        }}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            debouncing(handleClickPrevBtn, 200);
          }}
          sx={{ opacity: 0.8, ':hover': { opacity: 1 } }}
        >
          <PrevImageIcon
            sx={{
              width: { xs: '24px', md: '32px', lg: '40px' },
              height: { xs: '24px', md: '32px', lg: '40px' },
              color: 'white',
              filter: 'drop-shadow(8px rgba(0, 0, 0, 1))',
            }}
          />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            debouncing(handleClickNextBtn, 200);
          }}
          sx={{ opacity: 0.8, ':hover': { opacity: 1 } }}
        >
          <NextImageIcon
            sx={{
              width: { xs: '24px', md: '32px', lg: '40px' },
              height: { xs: '24px', md: '32px', lg: '40px' },
              color: 'white',
              filter: 'drop-shadow(8px rgba(0, 0, 0, 1))',
            }}
          />
        </IconButton>
      </Box>

      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          position: 'absolute',
          bottom: { sm: '7px', lg: '10px' },
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          alignItems: 'center',
          gap: { xs: 1, md: 1.5 },
          pl: 1,
          zIndex: 2,
        }}
      >
        {Array.from({ length: BANNER_IMAGE_COUNT }).map((_, index) => {
          const isCurrentImage = index + 1 === currentImageIndex;

          return (
            <Box
              key={index}
              onClick={() => {
                setCurrentImageIndex(index + 1);
                clearTimeout();
              }}
              sx={{
                width: isCurrentImage ? '24px' : '10px',
                height: '10px',
                bgcolor: 'white',
                borderRadius: 20,
                transition: 'width .3s',
                opacity: isCurrentImage ? 1 : 0.8,
                cursor: 'pointer',
              }}
            />
          );
        })}
        <IconButton
          size="small"
          onClick={() => {
            setIsAutoSlideMode(!isAutoSlideMode);
          }}
          sx={{ svg: { color: 'white' } }}
        >
          {isAutoSlideMode ? <AutoSlidePauseIcon /> : <AutoSlidePlayIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default MainPageBanner;
