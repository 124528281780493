import type { SxProps } from '@mui/material';
import { Box, Skeleton } from '@mui/material';

const MatchCardSkeletons = ({
  mode,
  sx,
}: {
  mode: 'default' | 'no-profile' | 'search';
  sx?: SxProps;
}) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns:
        mode === 'search'
          ? '1fr'
          : { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
      gap: 2,
      ...sx,
    }}
  >
    {Array(6)
      .fill(0)
      .map((_, idx) => (
        <Box key={idx} sx={{ display: 'flex', flexDirection: 'column' }}>
          {mode === 'search' ? (
            <Box sx={{ display: 'flex' }}>
              <Skeleton
                variant="rounded"
                sx={{
                  aspectRatio: '16 / 9',
                  borderRadius: { xs: 0, sm: 1.5 },
                  mr: 1.5,
                  height: { sm: '160px', md: '200px' },
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  m: 0.5,
                  gap: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{ height: '20px', mr: '10%' }}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ height: '20px', mr: '30%' }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton
                    variant="circular"
                    sx={{ height: '32px', width: '32px', mr: 2 }}
                  />
                  <Skeleton variant="rounded" sx={{ flex: 1, mr: '50%' }} />
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  width: '100%',
                  aspectRatio: '16 / 9',
                  mb: 1.5,
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: { xs: 0, sm: 1.5 },
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex' }}>
                {mode !== 'no-profile' && (
                  <Skeleton
                    variant="circular"
                    sx={{
                      height: '32px',
                      width: '32px',
                      m: 0.5,
                      ml: { xs: 2, sm: 0 },
                      mr: 1,
                    }}
                  />
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    m: 0.5,
                    gap: 1.5,
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: { xs: '25px', sm: '20px' }, mr: '10%' }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: { xs: '25px', sm: '20px' }, mr: '30%' }}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      ))}
  </Box>
);

export default MatchCardSkeletons;
