import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { Box, Typography, useTheme } from '@mui/material';

import SportsIcon from 'components/atoms/SportsIcon';

import useCheckDevice from 'hooks/useCheckDevice';

import type { SportType } from 'types/scoreboardData';

const LabeledSportsIcon = ({
  isInsideBottomSheet = false,
  isActive,
  sportType,
  onClick,
}: {
  isInsideBottomSheet?: boolean;
  isActive: boolean;
  sportType: SportType;
  onClick: () => void;
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation('main');
  const { isMobile } = useCheckDevice();
  const {
    palette: { mode },
  } = useTheme();

  return isInsideBottomSheet ? (
    <Box
      className={`gtm-sports-type-btn ${
        isActive ? 'gtm-active-sports-btn' : ''
      }`}
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        scrollSnapAlign: 'start',
        scrollSnapStop: 'normal',
        zIndex: 10,
        width: '62px',
        height: '64px',
      }}
    >
      <SportsIcon
        applyGradientColor={isActive}
        sportType={sportType}
        sx={{
          width: '50px',
          height: '50px',
          color: 'gray.400',
          p: 0.5,
        }}
      />
      <Typography
        variant="body3Bold"
        sx={{
          color: 'gray.400',
          width: '64px',
          lineHeight: locale === 'ko' ? 1.5 : 1.2,

          ...(isActive && {
            background:
              '-webkit-linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }),
        }}
      >
        {t(`sportType.${sportType}`)}
      </Typography>
    </Box>
  ) : (
    <Box
      className={`gtm-sports-type-btn ${
        isActive ? 'gtm-active-sports-btn' : ''
      }`}
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: { xs: 0.5, sm: 1 },
        px: { xs: '6px', sm: '12px' },
        cursor: { xs: 'default', sm: 'pointer' },
        zIndex: 10,

        ...(!isMobile && {
          ':hover': {
            div: {
              backgroundColor: mode === 'dark' ? '#172419' : '#EBF9EE',
            },
            svg: {
              color: 'primaryColor.500',
            },
            '> .MuiTypography-root': {
              color: 'primaryColor.500',
            },
          },
        }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: { xs: '50px', sm: '75px' },
          height: { xs: '50px', sm: '75px' },
          border: '2px solid transparent',
          borderRadius: '50%',
          backgroundImage: isActive
            ? mode === 'dark'
              ? 'linear-gradient(#172419, #172419), linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)'
              : 'linear-gradient(#EBF9EE, #EBF9EE), linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)'
            : 'none',
          bgcolor: 'gray.50',
          borderWidth: isActive ? '2px' : '0px',
          borderColor: isActive
            ? 'none'
            : mode === 'dark'
              ? 'gray.500'
              : 'gray.300',
          backgroundOrigin: 'border-box',
          backgroundClip: 'content-box, border-box',
        }}
      >
        <SportsIcon
          applyGradientColor={isActive}
          sportType={sportType}
          sx={{
            width: { xs: '30px', sm: '44px' },
            height: { xs: '30px', sm: '44px' },
            color: 'gray.500',
          }}
        />
      </Box>
      <Typography
        variant={isMobile ? 'body3Bold' : 'h5Bold'}
        sx={{
          color: 'gray.500',
          lineHeight: locale === 'ko' ? 1.5 : 1.2,

          ...(isActive && {
            background:
              '-webkit-linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }),
        }}
      >
        {t(`sportType.${sportType}`)}
      </Typography>
    </Box>
  );
};
export default LabeledSportsIcon;
