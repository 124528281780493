import { Fragment, useRef } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import ArrowIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Box, Button, Typography } from '@mui/material';

import EventCardSkeleton from 'components/atoms/EventCardSkeleton';
import BoxWithArrowIcon from 'components/molecules/BoxWithArrowIcon';
import EventCard from 'components/molecules/EventCard';
import NoResultGuideBox from 'components/molecules/NoResultGuideBox';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';

import type { EventResultPagesType } from 'types/event';
import type { SportType } from 'types/scoreboardData';

import EventAdCard from '../GoogleAds/EventAdCard';

const EventCardWrapper = ({
  eventListData,
  isFetching,
  initSportType,
}: {
  eventListData: EventResultPagesType | undefined;
  isFetching: boolean;
  initSportType?: SportType;
}) => {
  const { t } = useTranslation('event');
  const { isAuthenticatedUser, isSubscribedUser, signIn } = useActiveAuth();
  const { push } = useRouter();
  const { isMobile } = useCheckDevice();

  const cardWrapperEl = useRef<HTMLDivElement>(null);

  const isEmpty = eventListData && eventListData.events.length <= 0;

  const handleClickEventCreateBtn = () => {
    if (!isAuthenticatedUser) {
      signIn();
      return;
    }

    push('/event/create');
  };

  const handleOpenEventPage = () => {
    push(
      `/event` +
        (initSportType ? `?eventType=sport&sportType=${initSportType}` : ''),
    );
  };

  return (
    <Box id="gtm-event-area" sx={{ mt: { xs: '20px', sm: '28px', lg: 4 } }}>
      {!isMobile ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ml: { sm: 5, lg: 0 },
            mr: { sm: 2, lg: 0 },
          }}
        >
          <Typography
            variant="h3Bold"
            sx={{ fontSize: { xs: '20px', sm: '24px' } }}
          >
            {t('title.events')}
          </Typography>

          <Button
            className="gtm-event-btn"
            color="inherit"
            disableRipple
            sx={{
              px: 1,
              '&:hover': {
                bgcolor: 'transparent',
              },
            }}
            endIcon={<ArrowIcon />}
            onClick={handleOpenEventPage}
          >
            <Typography variant="h5Bold">{t('btn.showAll')}</Typography>
          </Button>
        </Box>
      ) : (
        <Button
          className="gtm-event-btn"
          color="inherit"
          disableRipple
          sx={{
            ml: 2,
            pl: 0,
            pr: 1,
            '&:hover': {
              bgcolor: 'transparent',
            },
          }}
          endIcon={<ArrowIcon sx={{ ml: -1 }} />}
          onClick={handleOpenEventPage}
        >
          <Typography
            variant="h3Bold"
            sx={{
              fontSize: { xs: '20px', sm: '24px' },
            }}
          >
            {t('title.events')}
          </Typography>
        </Button>
      )}

      <BoxWithArrowIcon
        className="gtm-event-browse-btn"
        childrenWrapperRef={cardWrapperEl}
        scrollGap={300}
        isHide={
          eventListData === undefined || isFetching
            ? true
            : eventListData.events.length <= 3
        }
      >
        {eventListData === undefined || isFetching ? (
          <Box
            sx={{
              display: 'flex',
              columnGap: { xs: 1, sm: '12px', lg: 2 },
              overflowX: 'hidden',
              px: { xs: 2, sm: 5, lg: 0 },
              pb: { xs: '14px', lg: '22px' },
              mt: { xs: '10px', sm: 1.5, lg: 3 },
            }}
          >
            <EventCardSkeleton usedPage="main" />
            <EventCardSkeleton usedPage="main" />
            <EventCardSkeleton usedPage="main" />
            <EventCardSkeleton usedPage="main" />
          </Box>
        ) : (
          <Box
            className="gtm-event-list-area"
            ref={cardWrapperEl}
            sx={{
              scrollSnapType: { lg: 'x mandatory' },
              overflowX: 'auto',
              overflowY: 'hidden',
              display: 'grid',
              gridTemplateColumns: `repeat(${
                eventListData.events.length > 3
                  ? eventListData.events.length + (isSubscribedUser ? 0 : 3)
                  : 6
              }, 1fr)`,
              columnGap: { xs: 1, sm: '12px', lg: 2 },
              pt: { xs: '10px', sm: '12px', lg: '22px' },
              pb: { xs: '12px', lg: '22px' },
              '&::-webkit-scrollbar': { display: 'none' },
              px: { xs: 2, sm: 5, lg: 0 },
            }}
          >
            {eventListData.events.map((event, idx) => (
              <Fragment key={event.id}>
                <EventCard isMyChannel={false} event={event} usedPage="main" />
                {idx % 4 === 3 ? <EventAdCard /> : null}
              </Fragment>
            ))}
          </Box>
        )}
      </BoxWithArrowIcon>

      {!isFetching && isEmpty ? (
        <NoResultGuideBox type="event" onClick={handleClickEventCreateBtn} />
      ) : null}
    </Box>
  );
};

export default EventCardWrapper;
