import type { ReactNode} from 'react';
import { useEffect, useRef, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import {
  Box,
  Button,
  Divider,
  Typography,
  useScrollTrigger,
  useTheme,
} from '@mui/material';

import { AddIcon, ForYouIcon, LiveIcon, NewIcon } from 'components/atoms/Icons';
import SportsIcon from 'components/atoms/SportsIcon';
import BoxWithArrowIcon from 'components/molecules/BoxWithArrowIcon';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';

import { APP_BAR_MOBILE_HEIGHT } from 'utils/getConstantNumber';

const MAIN_TAB_HEIGHT = 52;

const MainMenu = ({
  activeMenu,
  onClick,
}: {
  activeMenu: string;
  onClick: (selectedMenu: string) => void;
}) => {
  const { t } = useTranslation('main');
  const { isAuthenticatedUser, isAuthProcessComplete, signIn } =
    useActiveAuth();
  const { locale } = useRouter();
  const { isMobile, isPc, isMdToLg } = useCheckDevice();
  const isScrollDown = useScrollTrigger({ disableHysteresis: !isMobile });
  const {
    palette: { mode },
  } = useTheme();

  const [scrollPosition, setScrollPosition] = useState('left');

  const tabButtonWrapperEl = useRef<HTMLDivElement>(null);

  const isDrawGradient = tabButtonWrapperEl.current
    ? tabButtonWrapperEl.current.scrollWidth -
        tabButtonWrapperEl.current.clientWidth >=
      20
    : false;

  const mainMenuList: {
    type:
      | 'all'
      | 'forYou'
      | 'live'
      | 'new'
      | 'baseball'
      | 'soccer'
      | 'basketball'
      | 'esports'
      | 'sports';
    startIcon?: ReactNode;
    endIcon?: ReactNode;
  }[] = [
    { type: 'all' },
    { type: 'forYou', startIcon: <ForYouIcon /> },
    { type: 'live', startIcon: <LiveIcon /> },
    { type: 'new', startIcon: <NewIcon /> },
    { type: 'baseball', startIcon: <SportsIcon sportType="baseball" /> },
    { type: 'soccer', startIcon: <SportsIcon sportType="soccer" /> },
    { type: 'basketball', startIcon: <SportsIcon sportType="basketball" /> },
    { type: 'esports', startIcon: <SportsIcon sportType="esports" /> },
    { type: 'sports', endIcon: <AddIcon /> },
  ];

  const handleClickMenuButton = (menuType: string) => {
    if (menuType === 'forYou') {
      if (Boolean(isAuthProcessComplete) === false) return;
      if (!isAuthenticatedUser) {
        signIn();
        return;
      }
    }

    window.scrollTo({
      top: isMobile ? 40 : isPc ? 550 : isMdToLg ? 660 : 450,
      behavior: 'smooth',
    });

    onClick(menuType);
  };

  useEffect(() => {
    const container = tabButtonWrapperEl.current;
    const handleScroll = () => {
      if (container) {
        const scrollLeft = container.scrollLeft;
        const scrollWidth = container.scrollWidth;
        const containerWidth = container.clientWidth;
        const atRightEnd = scrollWidth === scrollLeft + containerWidth;

        if (atRightEnd) setScrollPosition('right');
        else if (scrollLeft === 0) setScrollPosition('left');
        else setScrollPosition('middle');
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <Box sx={{ display: { sm: 'none' } }}>
        <Box
          ref={tabButtonWrapperEl}
          sx={{
            position: 'sticky',
            top: isScrollDown
              ? `-${MAIN_TAB_HEIGHT}px`
              : `${APP_BAR_MOBILE_HEIGHT - 1}px`,

            display: 'flex',
            columnGap: '8px',
            ml: { xs: 0, sm: 2 },
            py: 1,
            pr: 2,
            bgcolor: 'background.default',
            transition: 'top .2s',
            zIndex: 100,
            overflowX: 'auto',
            scrollSnapType: 'x mandatory',
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Box
            sx={{
              mr: 1,
              scrollSnapAlign: 'start',
              scrollSnapStop: 'normal',
            }}
          />
          {mainMenuList.map(({ type, startIcon, endIcon }) => (
            <Button
              className={`gtm-view-type-btn ${
                activeMenu === type ? 'gtm-active-type-btn' : ''
              }`}
              key={type}
              color="inherit"
              disableRipple
              startIcon={startIcon}
              endIcon={endIcon}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: 2,
                height: '36px',
                minWidth: 'auto',
                borderRadius: '50px',
                border: '1px solid',
                borderColor:
                  activeMenu === type ? 'primaryColor.500' : 'gray.600',
                bgcolor:
                  activeMenu === type ? 'primaryColor.500' : 'transparent',
                color: activeMenu === type ? 'white' : 'gray.600',
                scrollSnapAlign: 'start',
                scrollSnapStop: 'normal',

                '& svg': {
                  width: '20px',
                  height: '20px',
                },

                '& svg path': {
                  fill: activeMenu === type ? 'white' : 'gray.600',
                },

                '&:hover': {
                  bgcolor:
                    activeMenu === type ? 'primaryColor.500' : 'transparent',
                },

                '& .MuiButton-startIcon': {
                  mr: startIcon ? 0.5 : 1,
                  ml: endIcon ? 0.5 : -0.5,
                },
              }}
              onClick={() => handleClickMenuButton(type)}
            >
              <Typography variant="h6Bold" sx={{ whiteSpace: 'nowrap' }}>
                {t(`mainMenu.${type}`)}
              </Typography>
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              pointerEvents: 'none',
              display: scrollPosition === 'left' ? 'none' : 'block',
              position: 'absolute',
              left: 0,
              width: '10%',
              maxWidth: '120px',
              height: '100%',
              background:
                mode === 'dark'
                  ? 'linear-gradient(90deg,#121212 0%, rgba(18, 18, 18, 0) 100%)'
                  : 'linear-gradient(90deg,#fff 0%, rgba(18, 18, 18, 0) 100%)',
              zIndex: 100,
            }}
          />
          <Box
            sx={{
              pointerEvents: 'none',
              display: scrollPosition === 'right' ? 'none' : 'block',
              position: 'absolute',
              right: 0,
              width: '10%',
              maxWidth: '120px',
              height: '100%',
              background:
                mode === 'dark'
                  ? 'linear-gradient(90deg, rgba(18, 18, 18, 0) 0%, #121212 100%)'
                  : 'linear-gradient(90deg, rgba(18, 18, 18, 0) 0%, #fff 100%)',
              zIndex: 100,
            }}
          />
        </Box>
        <Divider sx={{ my: '4px' }} />
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <BoxWithArrowIcon
          childrenWrapperRef={tabButtonWrapperEl}
          scrollGap={400}
          isHide={locale === 'ko'}
          top="calc(10%)"
        >
          <Box
            ref={tabButtonWrapperEl}
            sx={{
              position: 'sticky',
              top: '63px',
              transition: 'top .2s',
              bgcolor: 'background.default',
              zIndex: 20,
              display: 'flex',
              borderBottom: '1px inset',
              borderBottomColor: 'gray.300',
              mt: { sm: '30px', md: '40px' },
              ml: { sm: '40px', lg: 0 },
              overflowX: 'auto',
              scrollSnapType: 'x mandatory',
              '::-webkit-scrollbar': { display: 'none' },
            }}
          >
            {mainMenuList.map(({ type, startIcon, endIcon }) => (
              <Button
                className={`gtm-view-type-btn ${
                  activeMenu === type ? 'gtm-active-type-btn' : '11'
                }`}
                key={type}
                color="inherit"
                disableRipple
                startIcon={startIcon}
                endIcon={endIcon}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: { sm: 3, lg: 4 },
                  height: { sm: '44px', lg: '50px' },
                  minWidth: 'auto',
                  color: activeMenu !== type ? 'gray.500' : 'primaryColor.500',
                  borderBottom: '3px solid',
                  borderBottomColor:
                    activeMenu !== type ? 'transparent' : 'primaryColor.500',
                  borderRadius: 0,
                  scrollSnapAlign: 'start',
                  scrollSnapStop: 'normal',
                  zIndex: 11,

                  '& svg path': {
                    fill: activeMenu !== type ? '#9393A2' : '#37C556',
                  },

                  '&:hover': {
                    bgcolor: 'inherit',
                  },
                }}
                onClick={() => handleClickMenuButton(type)}
              >
                <Typography variant="h4Bold" sx={{ whiteSpace: 'nowrap' }}>
                  {t(`mainMenu.${type}`)}
                </Typography>
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              sx={{
                pointerEvents: 'none',
                display:
                  scrollPosition === 'left' || !isDrawGradient
                    ? 'none'
                    : 'block',
                position: 'absolute',
                left: 0,
                width: '10%',
                maxWidth: '120px',
                height: '100%',
                background:
                  mode === 'dark'
                    ? 'linear-gradient(90deg,#121212 0%, rgba(18, 18, 18, 0) 100%)'
                    : 'linear-gradient(90deg,#fff 0%, rgba(18, 18, 18, 0) 100%)',
                zIndex: 30,
              }}
            />
            <Box
              sx={{
                pointerEvents: 'none',
                display:
                  scrollPosition === 'right' || !isDrawGradient
                    ? 'none'
                    : 'block',
                position: 'absolute',
                right: 0,
                width: '10%',
                maxWidth: '120px',
                height: '100%',
                background:
                  mode === 'dark'
                    ? 'linear-gradient(90deg, rgba(18, 18, 18, 0) 0%, #121212 100%)'
                    : 'linear-gradient(90deg, rgba(18, 18, 18, 0) 0%, #fff 100%)',
                zIndex: 30,
              }}
            />
          </Box>
        </BoxWithArrowIcon>
      </Box>
    </>
  );
};

export default MainMenu;
