import useTranslation from 'next-translate/useTranslation';

import { Box, Button, Typography } from '@mui/material';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';

const NoResultGuideBox = ({
  type,
  onClick,
}: {
  type: string;
  onClick: () => void;
}) => {
  const { isAuthProcessComplete } = useActiveAuth();
  const { isMobile } = useCheckDevice();
  const { t } = useTranslation('main');

  return (
    <Box sx={{ textAlign: 'center', py: 3 }}>
      <Typography
        variant={isMobile ? 'body1Bold' : 'h4Bold'}
        sx={{ mb: '12px' }}
      >
        {t(`emptyResult.${type}.title`)}
      </Typography>
      <Typography variant={isMobile ? 'body2' : 'h4'} sx={{ mb: 3 }}>
        {t(`emptyResult.${type}.description`)}
      </Typography>
      <Button
        variant="contained"
        sx={{
          borderRadius: 10,
          background: isAuthProcessComplete
            ? 'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)'
            : 'linear-gradient(115deg, #A6A6A6 13.75%, #C0C0C0 82.09%)',
        }}
        onClick={onClick}
        disabled={Boolean(isAuthProcessComplete) === false}
      >
        <Typography variant={isMobile ? 'body1Bold' : 'h5Bold'}>
          {t(`emptyResult.${type}.button`)}
        </Typography>
      </Button>
    </Box>
  );
};

export default NoResultGuideBox;
