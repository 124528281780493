import type { SwipeableDrawerProps } from '@mui/material';
import { Box, SwipeableDrawer } from '@mui/material';

import LabeledSportsIcon from 'components/molecules/LabeledSportsIcon';

import type { SportType } from 'types/scoreboardData';

const BottomSheetSportsTypePicker = ({
  sportsList,
  selectedSportType,
  onClickSportsIcon,
  ...props
}: {
  sportsList: SportType[];
  selectedSportType: SportType;
  onClickSportsIcon: (sportType: SportType) => void;
} & SwipeableDrawerProps) => (
  <SwipeableDrawer
    anchor="bottom"
    disableSwipeToOpen={true}
    PaperProps={{
      sx: {
        display: 'flex',
        p: '24px',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
    }}
    {...props}
  >
    <Box
      id="gtm-sports-type-area"
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 64px)',
        justifyContent: 'center',
        columnGap: 2,
        rowGap: 3,
        pb: 4,
      }}
    >
      {sportsList.map((sportType) => (
        <LabeledSportsIcon
          key={sportType}
          isInsideBottomSheet
          isActive={selectedSportType === sportType}
          sportType={sportType}
          onClick={() => onClickSportsIcon(sportType)}
        />
      ))}
    </Box>
  </SwipeableDrawer>
);

export default BottomSheetSportsTypePicker;
