import { Fragment, useState } from 'react';

import dynamic from 'next/dynamic';

import { Box } from '@mui/material';

import CustomCircularProgress from 'components/atoms/CustomCircularProgress';
import NoResultGuideBox from 'components/molecules/NoResultGuideBox';
import PublicMatchListCard from 'components/organisms/PublicMatchList//PublicMatchListCard';
import MatchCardSkeletons from 'components/organisms/PublicMatchList/PublicMatchListCard/MatchCardSkeletons';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useVideoPlayList from 'hooks/useVideoPlayList';

import type { ResultPagesType } from 'types/matchData';

import ResponsiveBannerAdCard from '../GoogleAds/ResponsiveBannerAdCard';

const MatchGeneratePopup = dynamic(
  () => import('components/organisms/MatchGeneratePopup'),
  { loading: () => <CustomCircularProgress /> },
);

const PublicMatchListCardWrapper = ({
  tabType,
  resultPages,
  isLoading,
}: {
  tabType: 'staffPick' | 'today' | 'follow' | 'sport' | 'search' | 'live';
  resultPages: ResultPagesType[] | undefined;
  isLoading: boolean;
}) => {
  const { user, isAuthenticatedUser, isAuthProcessComplete, signIn } =
    useActiveAuth();
  const { isMobile } = useCheckDevice();
  const { focusVideoIndex, addPlayList, deletePlayList } = useVideoPlayList();

  const [isMatchPopupOpen, setIsMatchPopupOpen] = useState(false);

  const handleClickMatchCreateBtn = () => {
    if (Boolean(isAuthProcessComplete) === false) return;

    if (!isAuthenticatedUser) {
      signIn();
      return;
    }

    setIsMatchPopupOpen(true);
  };

  return isLoading ? (
    <MatchCardSkeletons
      mode={tabType === 'search' && !isMobile ? 'search' : 'default'}
    />
  ) : resultPages && resultPages[0].matches.length > 0 ? (
    <Box
      className="gtm-match-list-area"
      sx={{
        display: tabType === 'search' ? 'block' : 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: '1fr 1fr',
          md: '1fr 1fr 1fr',
        },
        px: 0,
        gap: 2,
        mt: tabType === 'search' ? { xs: 2, sm: 0 } : 0,
      }}
    >
      {resultPages.map((page, pageIdx) =>
        page.matches.map((matchData, idx) => (
          <Fragment key={matchData.id}>
            <PublicMatchListCard
              pageId={tabType === 'search' ? 'search' : 'default'}
              idx={idx + pageIdx * 12}
              isMyPage={matchData.uid === user?.id}
              matchListData={matchData}
              focusVideoIndex={focusVideoIndex}
              addPlayList={(idx: number) => addPlayList(idx)}
              deletePlayList={(idx: number) => deletePlayList(idx)}
            />

            {(
              isMobile
                ? (idx + pageIdx * 12) % 6 === 5
                : (idx + pageIdx * 12) % 12 === 11
            ) ? (
              <ResponsiveBannerAdCard
                adId="모든_경기리스트_배너_공통"
                sx={{
                  gridColumn: {
                    xs: `auto / span 1`,
                    sm: `auto / span 2`,
                    md: `auto / span 3`,
                  },
                  mb: 2,
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              />
            ) : null}
          </Fragment>
        )),
      )}
    </Box>
  ) : tabType !== 'search' ? (
    <>
      <NoResultGuideBox
        type={tabType === 'live' ? 'live' : 'match'}
        onClick={handleClickMatchCreateBtn}
      />

      {isMatchPopupOpen ? (
        <MatchGeneratePopup
          open={isMatchPopupOpen}
          close={() => setIsMatchPopupOpen(false)}
        />
      ) : null}
    </>
  ) : null;
};

export default PublicMatchListCardWrapper;
