import { useEffect, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import CustomCancelButton from 'components/atoms/CustomCancelButton';
import CustomSetButton from 'components/atoms/CustomSetButton';
import SportsIcon from 'components/atoms/SportsIcon';

import useCheckDevice from 'hooks/useCheckDevice';
import useTranslationList from 'hooks/useTranslationList';

import type { SportType } from 'types/scoreboardData';

import sportTypeList from 'utils/sportTypeList';

import BottomSheet from '../BottomSheet';
import LabeledSportsIcon from '../LabeledSportsIcon';

const MultiSportPicker = ({
  open,
  initSportsList,
  onOpen,
  onClose,
  onChangeSportsList,
}: {
  open: boolean;
  initSportsList: SportType[];
  onOpen: () => void;
  onClose: () => void;
  onChangeSportsList: (selectedSportsList: SportType[]) => void;
}) => {
  const {
    palette: { mode },
  } = useTheme();
  const { t } = useTranslation('common');
  const { getSportTypeList } = useTranslationList();
  const { isMobile } = useCheckDevice();

  const [selectedSportsList, setSelectedSportsList] =
    useState<SportType[]>(initSportsList);

  const sportTypeListWithoutAll = sportTypeList.filter(
    (sportType) => sportType !== 'all',
  );

  const handleClickSportType = async (selectedInterest: SportType) => {
    if (selectedSportsList.includes(selectedInterest)) {
      const updatedInterestList = selectedSportsList.filter(
        (interest) => interest !== selectedInterest,
      );
      setSelectedSportsList(updatedInterestList);
    } else if (selectedSportsList.length < 3) {
      setSelectedSportsList([...selectedSportsList, selectedInterest]);
    } else {
      enqueueSnackbar(t('multiSportPicker.notice'), {
        variant: 'warning',
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    setSelectedSportsList(initSportsList);
  }, [initSportsList]);

  return isMobile ? (
    <BottomSheet open={open} onClose={onClose} onOpen={onOpen}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h4Bold">{t('multiSportPicker.title')}</Typography>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 64px)',
            justifyContent: 'center',
            columnGap: 2,
            rowGap: 3,
            pb: 4,
          }}
        >
          {sportTypeListWithoutAll.map((sports) => (
            <LabeledSportsIcon
              key={sports}
              isInsideBottomSheet
              isActive={selectedSportsList.includes(sports)}
              sportType={sports}
              onClick={() => handleClickSportType(sports)}
            />
          ))}
        </Box>

        <Button
          fullWidth
          variant="contained"
          sx={{ height: '48px' }}
          onClick={() => {
            onChangeSportsList(selectedSportsList);
            onClose();
          }}
        >
          <Typography variant="h5Bold">
            {t('multiSportPicker.saveBtn')}
          </Typography>
        </Button>
      </Box>
    </BottomSheet>
  ) : (
    <Dialog
      open={open}
      onClick={(e) => e.stopPropagation()}
      onClose={(_e, reason) => {
        if (reason === 'escapeKeyDown' || !reason) onClose();
      }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          width: { md: '90%' },
          maxWidth: { md: '860px' },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4Bold">{t('multiSportPicker.title')}</Typography>
      </DialogTitle>

      <DialogContent sx={{ '::-webkit-scrollbar': { display: 'none' } }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {getSportTypeList().map((item) => (
            <Box
              key={item.value}
              sx={{
                p: 1,
                mb: { xs: '3%', sm: '2%' },
                width: { xs: '48.5%', sm: '32%' },
                textAlign: 'center',
                border: '1px solid white',
                bgcolor: selectedSportsList.includes(item.value)
                  ? 'rgba(55, 197, 86, 0.10)'
                  : 'transparent',
                borderColor: selectedSportsList.includes(item.value)
                  ? 'primaryColor.500'
                  : mode === 'dark'
                    ? 'white'
                    : 'deprecatedGray.20',
                borderRadius: 2.5,
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={() => handleClickSportType(item.value)}
            >
              <SportsIcon
                sportType={item.value as SportType}
                sx={({ palette: { mode } }) => ({
                  width: '32px',
                  height: '32px',
                  color: selectedSportsList.includes(item.value)
                    ? 'primaryColor.500'
                    : mode === 'dark'
                      ? 'white'
                      : 'deprecatedGray.20',
                })}
              />
              <Typography
                variant="body1"
                sx={{
                  color: selectedSportsList.includes(item.value)
                    ? 'primaryColor.500'
                    : mode === 'dark'
                      ? 'white'
                      : 'deprecatedGray.20',
                }}
              >
                {item.content}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions sx={{ pr: 3, pb: 2 }}>
        <CustomCancelButton
          title={t('multiSportPicker.closeBtn')}
          onClick={onClose}
        />
        <CustomSetButton
          title={t('multiSportPicker.saveBtn')}
          onClick={() => {
            onChangeSportsList(selectedSportsList);
            onClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default MultiSportPicker;
