import { Box, Skeleton } from '@mui/material';

const MatchCardSkeleton = ({
  mode,
}: {
  mode: 'default' | 'no-profile' | 'search';
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
    <Skeleton
      variant="rounded"
      sx={{
        aspectRatio: '16 / 9',
        borderRadius: 1.5,
        mb: 1.5,
        height: '100%',
        width: { xs: '208px', sm: '320px', lg: '400px' },
      }}
    />
    <Box sx={{ display: 'flex' }}>
      {mode !== 'no-profile' && (
        <Skeleton
          variant="circular"
          sx={{
            height: '32px',
            width: '32px',
            m: 0.5,
            ml: { xs: 2, sm: 0 },
            mr: 1,
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          m: 0.5,
          gap: 1.5,
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{ height: { xs: '25px', sm: '20px' }, mr: '10%' }}
        />
        <Skeleton
          variant="rounded"
          sx={{ height: { xs: '25px', sm: '20px' }, mr: '30%' }}
        />
      </Box>
    </Box>
  </Box>
);

export default MatchCardSkeleton;
