import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Box, useTheme } from '@mui/material';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';

import CustomAdCard from './CustomAdCard';

const KO_DEFAULT_CARD_IMG_NUM = 4;
const EN_DEFAULT_CARD_IMG_NUM = 4;

const EventAdCard = () => {
  const {
    palette: { mode },
  } = useTheme();
  const { isSubscribedUser, isProfileLoadProcessComplete } = useActiveAuth();
  const { isMobile, isTablet, isPc } = useCheckDevice();
  const { locale } = useRouter();

  const [defaultImageNum, setDefaultImageNum] = useState(0);

  const isAndroid =
    typeof navigator !== 'undefined' && navigator.userAgent.match(/Android/i);

  const cardSize = {
    mobile: {
      width: '210px',
      height: '280px',
    },
    tablet: {
      width: '250px',
      height: '333px',
    },
    pc: {
      width: '300px',
      height: '400px',
    },
  };

  const cardLink: {
    ko: { [key: number]: string };
    en: { [key: number]: string };
  } = {
    ko: {
      0: 'https://cafe.naver.com/camerafilive/4384',
      1: 'https://www.facebook.com/camerafikor?locale=ko_KR',
      2: 'https://www.instagram.com/camerafi_/',
      3: isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.vaultmicro.camerafi.live&pcampaignid=web_share'
        : 'https://apps.apple.com/us/app/camerafi-live/id6448773005',
    },
    en: {
      0: 'https://blog.camerafi.com/p/tutorials-how-to-use-camerafi-studio.html#',
      1: 'https://www.facebook.com/groups/170216666130703',
      2: 'https://www.instagram.com/camerafi_/',
      3: isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.vaultmicro.camerafi.live&pcampaignid=web_share'
        : 'https://apps.apple.com/us/app/camerafi-live/id6448773005',
      4: 'https://www.youtube.com/@CameraFi',
    },
  };

  const handleClickDefaultAd = () => {
    window.open(
      cardLink[locale === 'ko' ? 'ko' : 'en'][defaultImageNum],
      '_blank',
    );
  };

  useEffect(() => {
    const getRandomNumForDefaultImg = () => {
      const max =
        locale === 'ko' ? KO_DEFAULT_CARD_IMG_NUM : EN_DEFAULT_CARD_IMG_NUM;

      return Math.floor(Math.random() * max);
    };
    const randomNum = getRandomNumForDefaultImg();

    setDefaultImageNum(randomNum);
  }, [locale]);

  return isProfileLoadProcessComplete && !isSubscribedUser ? (
    <Box
      sx={{
        minWidth: {
          xs: cardSize.mobile.width,
          sm: cardSize.tablet.width,
          lg: cardSize.pc.width,
        },
        minHeight: {
          xs: cardSize.mobile.height,
          sm: cardSize.tablet.height,
          lg: cardSize.pc.height,
        },
        scrollSnapAlign: 'start',
        borderRadius: '6px',
        overflow: 'hidden',
        border: '1px solid',
        borderColor: mode === 'dark' ? 'gray.100' : '#E5E5E9',
        position: 'relative',

        ...(isPc && {
          transition: 'transform .3s',
          ':hover': {
            transform: 'translateY(-8px)',
            boxShadow:
              mode === 'dark'
                ? '0px 8px 16px rgba(120, 120, 120, 0.25)'
                : '0px 8px 16px rgba(0, 0, 0, 0.25)',
          },
        }),
      }}
    >
      {isMobile ? (
        <CustomAdCard
          adId="모든_이벤트리스트_카드_공통"
          width={cardSize.mobile.width}
          height={cardSize.mobile.height}
          defaultImageURL={`/img/ad_googleDefault/card${defaultImageNum}_${
            locale || 'en'
          }.webp`}
          onClick={handleClickDefaultAd}
        />
      ) : null}

      {isTablet ? (
        <CustomAdCard
          adId="모든_이벤트리스트_카드_공통"
          width={cardSize.tablet.width}
          height={cardSize.tablet.height}
          defaultImageURL={`/img/ad_googleDefault/card${defaultImageNum}_${
            locale || 'en'
          }.webp`}
          onClick={handleClickDefaultAd}
        />
      ) : null}

      {isPc ? (
        <CustomAdCard
          adId="모든_이벤트리스트_카드_공통"
          width={cardSize.pc.width}
          height={cardSize.pc.height}
          defaultImageURL={`/img/ad_googleDefault/card${defaultImageNum}_${
            locale || 'en'
          }.webp`}
          onClick={handleClickDefaultAd}
        />
      ) : null}
    </Box>
  ) : null;
};

export default EventAdCard;
